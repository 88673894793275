<!--
 主页
-->

<template>
  <div>
    <div class="home-bg"></div>
    <div class="home-box">
      <!-- <div class="home-title">数字藏品商城</div> -->
      <div v-if="!isLogin || !isVerify" class="home-auth-hint" @click="goToAuth">
        <span>为保证顺利抢购，请提前完成实名认证 <van-icon name="arrow" /></span>
      </div>

      <van-pull-refresh v-model="loadingPull" @refresh="onRefresh">
        <div class="home-hint">
          <div class="home-hint-text">数字藏品是基于区块链技术发行的<br />限量加密数字商品</div>
        </div>
        <!-- @click="bannerClick(item)" @touchstart="clickStart"
              @touchend="clickEnd(item)" -->
        <div class="my-swipe">
          <van-swipe class="" :autoplay="3000" indicator-color="white" :stop-propagation="false">
            <van-swipe-item
              v-for="(item, index) in bannerList"
              :key="index"
              @click="bannerClick(item)"
            >
              <div class="swipe">
                <img :src="item.image" alt="" />
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="tabs-box">
          <van-tabs
            @click="onChangeTab"
            background="transparent"
            line-height="0"
            :title-active-color="'#fff'"
            :title-inactive-color="'rgba(255,255,255,0.7000)'"
            v-model:active="tabValue"
          >
            <van-tab title="线上精品" :name="1"></van-tab>
            <van-tab title="往日发售" :name="2"></van-tab>
          </van-tabs>
        </div>
        <div class="history-box" v-if="tabValue == 2">
          <div class="" v-for="(item, index) in historyList" :key="index">
            <div class="title">
              <span>《{{ item.group_name }}》系列</span>
            </div>
            <div class="sub-item-box">
              <div class="" v-for="(subitem, subindex) in item.list" :key="subindex">
                <div class="card-item" @click="goToDetail(subitem)">
                  <div class="img-box">
                    <div class="card-wrap">
                      <img class="card" fit="contain" :src="subitem.image_icon" alt="" />
                      <!-- <img class="card primary-color" fit="contain" :src="img1" alt="" /> -->
                    </div>
                    <div class="over">已结束</div>
                    <div class="msg-meng"></div>
                  </div>

                  <div class="msg">
                    <div class="flex-bt">
                      <div class="">限{{ subitem.total_num }}份</div>
                      <div class="primary-color"><span>¥</span> {{ subitem.pay_price / 100 }}</div>
                    </div>
                    <div class="name oneline">
                      {{ subitem.type == 2 ? subitem.collections_name : subitem.items_name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="goods" v-if="tabValue == 1">
          <div class="good" v-for="(item, index) in list" :key="index">
            <div class="good-top">
              <div class="good-header">
                <div>
                  <!-- 限量 -->
                  <span
                    ><span>{{ item.is_pre_sale == 1 ? '预售' : '限量发售' }}</span>
                    <span class="primary-color m-font">{{ item.total_num }}</span
                    >份</span
                  >
                </div>
                <div class="s-font">
                  <span class="primary-color" @click="showPublisher">{{ item.publisher }}</span
                  >发行
                </div>
              </div>

              <div class="good-image" @click="goToDetail(item)">
                <van-image :src="item.image_bg" style="pointer-events: auto !important" />
                <div class="tag-shadow"></div>

                <div v-if="item.status === 2" class="tag">
                  <span class="tag-no">{{ item.is_pre_sale == 1 ? '预售结束' : '已结束' }}</span>
                </div>
                <div v-else class="tag">
                  剩余<span class="primary-color s-font">{{ item.total_num - item.sale_num }}</span
                  >份
                </div>
                <!-- <div class="tag-bg"></div> -->
                <div class="tag-detail">详情<van-icon :name="icon_arrow" class="icon_arrow" /></div>
              </div>
            </div>
            <div class="good-bottom" v-if="item.price == item.pay_price">
              <span class="good-text-normal"
                >《{{ item.display_name }}》{{
                  item.type == 2 ? item.collections_name : item.items_name
                }}</span
              >
              <div>
                <span class="primary-color">¥</span
                ><span class="primary-color l-font good-text-price">{{ item.price / 100 }}</span>
              </div>
            </div>
            <div class="good-bottom-line t-c" v-else>
              <div class="item-title">
                <span class=""
                  >{{ item.display_name
                  }}{{ item.type == 2 ? item.collections_name : item.items_name }}</span
                >
              </div>
              <div>
                <span class="primary-color">¥</span
                ><span class="primary-color l-font good-text-price">{{
                  item.pay_price / 100
                }}</span>
                <span class="line-price">{{ item.price / 100 }}</span>
                <span class="price-tips">邀新奖励券后价</span>
              </div>
            </div>

            <div class="" v-if="showBtn">
              <box-btn
                v-if="item.is_draw_lots == 1 && item.pre_type && item.lots_info.status_type <= 0"
                @finistTime="finistTime"
                :class="item.price == item.pay_price ? 'box-btn' : 'box-not-btn'"
                :time="item.start_time"
                :status="item.status"
                :is_pre_sale="item.is_pre_sale"
                :total_num="item.total_num"
                :presale_start_time="item.presale_start_time"
                :presale_end_time="item.presale_end_time"
                :is_advance="item.is_advance"
                :pre_type="item.pre_type"
                @click="clickBtn(item)"
                :type="item.type"
                :server_time="server_time"
              >
              </box-btn>

              <boxDraw
                v-else-if="item.is_draw_lots == 1"
                @getList="finistTime"
                :itemData="item"
                class="box-btn"
                @drawLuckyBug="drawLuckyBug"
                :collections_id="item.id"
              />

              <box-btn
                v-else
                @finistTime="finistTime"
                :class="item.price == item.pay_price ? 'box-btn' : 'box-not-btn'"
                :time="item.start_time"
                :status="item.status"
                :is_pre_sale="item.is_pre_sale"
                :total_num="item.total_num"
                :presale_start_time="item.presale_start_time"
                :presale_end_time="item.presale_end_time"
                :is_advance="item.is_advance"
                @click="clickBtn(item)"
                :type="item.type"
                :server_time="server_time"
              >
              </box-btn>
            </div>
          </div>
          <div v-if="!list.length" class="dataNullBox">
            <div>
              <img src="./../imgs/comm/dataNull.png" alt="" srcset="" />
            </div>
            <div class="text">敬请期待</div>
            <div class="tohistory" @click="tabValue = 2">
              查看 往日发售 <van-icon name="arrow" />
            </div>
          </div>
          <div class="good-space"></div>
        </div>
      </van-pull-refresh>
      <!-- <div class="invit" @click="toinvit">
        <img src="@/imgs/comm/invit.png" alt="" />
      </div> -->
      <!-- 2022国庆节活动入口（过后可以删掉） -->
      <!-- <div class="signEntry" @click="toNationalSign">
        <img src="https://activity-resource.321mh.com/nft/notice/national_sign_2.png" />
      </div> -->

      <!-- 上传截图入口 -->
      <!-- <div class="signEntry" @click="toUpload">
        <img src="@/imgs/comm/icon_ccjt_rk.png" alt="" />
      </div> -->

      <div class="signEntry" @click="tofootBallDraw">
        <img src="https://activity-resource.321mh.com/nft/sgsd/icon_football.png" />
      </div>

      <!-- <div class="space-bottom"></div> -->
    </div>
    <div class="" v-if="showPayTyoe">
      <PayType
        v-model:show="showPayTyoe"
        :box="boxitem"
        :payStatus="payStatus"
        @close="showPayTyoe = false"
      />
    </div>

    <van-popup class="vantPop" v-model:show="drawPop.drawShow" :close-on-click-overlay="false">
      <div class="popup">
        <div class="content">
          <div class="title">{{ drawPop.drawTitle }}</div>
          <div v-html="drawPop.drawContent"></div>
        </div>
        <div class="btn">
          <div @click="closeDrawPop">知道了</div>
        </div>
      </div>
    </van-popup>

    <kmh-sheet v-model:show="show" @close="show = false"> </kmh-sheet>
    <OpenTheBox v-if="showOpenBox" @close="showOpenBox = false" :order="order" />
    <prepaidPoup v-if="prepaidShow" :prepaidShow="prepaidShow" :homePoup="homePoup" />
    <PayCbNew v-if="showNewBox" :order="order" />
    <PageLoading :show="loading" />
  </div>
</template>

<script>
import { reactive, onMounted, toRefs, onBeforeUpdate } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { nftUtils, domUtil, localStorageUtil } from '@/utils';
import kmhSheet from '@/components/KmhSheet';
import boxBtn from '@/components/BoxBtn';
import PayType from '@/components/newPayType';
import { useStore } from 'vuex';
import moment from 'moment';
import prepaidPoup from '@/components/prepaidPoup';
import { shareHelper } from '@/helpers';
import { Toast } from 'vant';
import OpenTheBox from '@/components/OpenTheBox.vue';
import PageLoading from '@/components/loading/PageLoading.vue';
import PayCbNew from '@/components/PayCbNew.vue';

import { checkDrawPop, confirmDrawPop } from '@/service/home';

import boxDraw from '@/components/BoxBtnDraw';
import {
  checkorderispay,
  homeList,
  getmergeconfig,
  getbannerlist,
  historylist
} from '@/service/home';

export default {
  name: 'home',
  components: {
    kmhSheet,
    boxBtn,
    prepaidPoup,
    PayType,
    OpenTheBox,
    PageLoading,
    PayCbNew,
    boxDraw
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const from = route.query.from;
    if ('sand' === from && localStorage.getItem('sandpay_mobile')) {
      // 如果来自衫德支付认证成功
      router.push({ path: '/collectionSend', query: { from: 'sand' } });
    }
    const state = reactive({
      isLogin: false, // 是否已登录
      isVerify: false, //是否认证
      show: false, //显示看漫简介
      showTitle: true,
      list: [],
      loading: true,
      showPayTyoe: false,
      boxitem: null,
      showOpenBox: false, // 是否显示开盲盒弹窗
      order: '',
      payStatus: 1, //1--正常支付，2--预付款
      prepaidItem: null, //弹窗内容
      prepaidShow: false, //是否显示弹窗
      showBtn: true,
      loadingPull: false,
      howLong: null,
      homePoup: {},
      tabValue: 1,
      bannerList: [],
      historyList: [], // 往期发售列表
      showNewBox: false, //新人盲盒弹窗
      server_time: 0,

      drawPop: {
        drawShow: false,
        drawTitle: '恭喜中奖',
        drawContent:
          '恭喜获得<span style="color: #3cdbe8;">[藏品名称]</span>的购买资格，8月15日16:00 结束购买，请及时抢购哦~'
      }
    });
    onBeforeUpdate(async () => {
      if (localStorageUtil.get('orderId')) {
        // 判断是否有已经支付成功的订单
        let trade_no = localStorageUtil.get('orderId');
        const { data } = await checkorderispay({ trade_no });
        localStorageUtil.del('orderId');
        // 是新人盲盒
        let isNew = localStorageUtil.get('isNew') ? localStorageUtil.get('isNew') : 0;
        localStorageUtil.del('isNew');
        if (data) {
          state.order = trade_no;
          setTimeout(() => {
            if (isNew == 1) {
              // 弹窗--新人盲盒
              // state.showNewBox = true;
            } else {
              state.showOpenBox = true;
            }
          }, 20);
          // router.push({ path: `/boxopen/${trade_no}` });
        }
      }
      if (localStorageUtil.get('refresh')) {
        localStorageUtil.del('refresh');
        state.showBtn = false;
        setTimeout(() => {
          getList();
        }, 500);
      }
    });
    const clickStart = () => {
      state.howLong = new Date().getTime();
    };
    const clickEnd = item => {
      var duration = new Date().getTime() - state.howLong;
      console.log(2222, duration);
      if (duration < 120) {
        bannerClick(item);
      }
    };

    const onChangeTab = name => {
      state.tabValue = name;
    };

    const getBanner = async () => {
      // 获取banner
      const { data, server_time } = await getbannerlist();
      state.server_time = server_time;
      state.bannerList = data;
    };

    // 轮播图点击跳转
    const bannerClick = item => {
      window.location.href = item.link_url.replace('https:', '').replace('http:', '');
      // console.log(item.link_url);
      // router.push({ path: item.link_url, query: item.query ? item.query : '' });
    };
    const getList = async () => {
      await store.dispatch('getHomeList');
      state.list = store.state.homeList;
      state.showBtn = true;
    };

    // 抽签弹窗
    const checkDrawPopHandle = () => {
      checkDrawPop({}).then(res => {
        console.log(res);
        if (res.status == 0) {
          // 恭喜中签
          if (!res.data.popup_status && res.data.is_lots == 1) {
            state.drawPop = {
              drawShow: true,
              drawTitle: '恭喜中签',
              drawContent: `恭喜您，获得<span style="color: #3cdbe8;">${res.data.collections_name}</span>的购买资格，请及时购买哦~`
            };

            // if (res.data.start_time) {
            //   state.drawPop.drawContent = `恭喜获得<span style="color: #3cdbe8;">${
            //     res.data.collections_name
            //   }</span>的购买资格，${getTime(res.data.start_time)} 结束售卖限时限量，请及时抢购哦～`;
            // }
          }

          // 遗憾未中签
          if (!res.data.popup_status && res.data.is_lots !== 1) {
            state.drawPop = {
              drawShow: true,
              drawTitle: '遗憾未中签',
              drawContent: `很遗憾，您没有中签，下次再接再厉`
            };

            // if (res.data.end_time) {
            //   state.drawPop.drawContent = `很遗憾未获得<span style="color: #3cdbe8;">${
            //     res.data.collections_name
            //   }</span>的购买资格，${getTime(
            //     res.data.end_time
            //   )} 可能还有抢购机会，请注意查收短信哦～`;
            // }
          }
        }
      });
    };

    // 确认抽签结果已弹窗
    const closeDrawPop = () => {
      confirmDrawPop({}).then(res => {
        if (res.status == 0) {
          state.drawPop.drawShow = false;
        }
      });
    };

    // 获取渠道来源
    const getSource = () => {
      if (route.query.source) {
        localStorageUtil.set('utm_source', route.query.source);
      }
    };

    onMounted(async () => {
      getSource();
      //localStorageUtil.set('orderId', 'TSTK20220921150533445');
      // localStorageUtil.set('isNew', 1);

      state.isLogin = nftUtils.isLogin();
      state.list = store.state.homeList;
      state.historyList = store.state.homehistoryList;
      state.list.length != 0 && (state.loading = false);
      if (state.list.length == 0 && state.historyList.length != 0) {
        state.tabValue = 2;
        state.loading = false;
      }
      // 获取历史列表
      getHistorylis();
      getBanner();
      await store.dispatch('getHomeList');
      state.list = store.state.homeList;
      if (state.list.length == 0) state.tabValue = 2;
      state.loading = false;
      // state.list.forEach(item => {
      //   item.start_time = '2022-05-18T16:00:00.000Z';
      //   item.status = 2;
      // });
      //     console.log(store.state.showPrepaidHome);
      // store.commit('changeShowPrepaidHome', true);
      //是否显示预付款弹窗
      showPrepaidPoup();
      if (state.isLogin) {
        state.isVerify = store.state.loginInfo ? !!store.state.loginInfo.id_verify : false;
        await store.dispatch('refreshLoginInfo');
        state.isVerify = store.state.loginInfo ? !!store.state.loginInfo.id_verify : false;
        checkDrawPopHandle();
      }
      const shareConf = {
        title: '看漫数藏',
        content: '由看漫APP倾力打造，藏品有限，价值无限',
        image: '',
        link: location.href, // app分享链接
        callback: shareCb
      };
      // 设置微信分享和app内部分享(shareInfo：为动态获取数据。如果是静态的分享，则直接在data初始化中设置)
      shareHelper.init(shareConf);
      // 设置index.html中的头部分享信息
      domUtil.setDomShareMeta(shareConf);
    });

    const getHistorylis = async () => {
      // const { data } = await historylist();
      // state.historyList = data;
      await store.dispatch('getHomeHistoryList');
      state.historyList = store.state.homehistoryList;
    };

    //下拉刷新
    const onRefresh = async () => {
      getBanner();
      await store.dispatch('getHomeList');
      getHistorylis();
      state.list = store.state.homeList;
      state.loadingPull = false;
    };
    //是否显示预付款弹窗
    const showPrepaidPoup = async () => {
      //  console.log(moment(new Date()).format('YYYY-MM-DD'), 'oooo');
      // state.prepaidShow = true;
      const { data } = await getmergeconfig();
      // let nowtime;
      if (data.mark) {
        let showDate = localStorageUtil.get('showDate')
          ? JSON.parse(localStorageUtil.get('showDate'))
          : [];
        console.log(showDate);
        let now = moment(new Date()).format('YYYY-MM-DD');
        if (showDate.includes(now)) {
          return;
        } else {
          state.homePoup = {
            img_url: data.img_url,
            link_url: data.link_url
          };
          state.prepaidShow = true;
          showDate.push(now);
          localStorageUtil.set('showDate', JSON.stringify(showDate));
        }
      } else {
        localStorageUtil.del('showDate');
      }

      // 永久不弹出 ｜｜ 本次已经弹出
      // if (!(localStorageUtil.get('notShowImg') || store.state.showPrepaidHome)) {
      //   let item,
      //     now = new Date().getTime();
      //   for (let i = 0; i < state.list.length; i++) {
      //     item = state.list[i];
      //     if (
      //       moment(item.presale_start_time).valueOf() < now &&
      //       now < moment(item.presale_end_time).valueOf() &&
      //       !item.is_advance
      //     ) {
      //       state.prepaidItem = item;

      //       console.log(state.prepaidItem, '...state.prepaidItem');
      //       break;
      //     }
      //   }
      // }
    };

    const shareCb = () => {
      // console.log('分享成功！！！');
      // Toast('分享成功！');
    };

    const toinvit = () => {
      router.push({ path: `/InviteActivity` });
    };

    const goToDetail = item => {
      nftUtils.setcnzzRecordByBtn('主页图片点击');
      let path = item.type == 1 ? `/product/${item.id}&${item.itmes_id}` : `/blindbox/${item.id}`;
      router.push({ path });
    };

    const drawLuckyBug = item => {
      clickBtn(item);
    };

    const clickBtn = item => {
      // state.showPayTyoe = true;
      nftUtils.setcnzzRecordByBtn('主页按钮点击');
      state.payStatus = 1; //重置支付类型
      // 唤起支付
      let now = new Date().getTime();
      state.boxitem = item;
      if (!state.isLogin) {
        router.push({ path: `/login` });
        return;
      }
      if (!state.isVerify) {
        Toast('需要实名认证后才能购买');
        setTimeout(() => {
          router.push({ path: `/authentication` });
        }, 1000);
        return;
      }
      if (item.status == 2) {
        Toast(item.is_pre_sale == 1 ? '预售结束' : '已结束');
        return;
      }
      if (diffTime(item.start_time) > 0) {
        // 是否在支付预付款时间段
        if (
          !(
            moment(item.presale_start_time).valueOf() < now &&
            now < moment(item.presale_end_time).valueOf()
          ) ||
          item.is_advance
        ) {
          Toast('未到开售时间');
          return;
        } else {
          state.payStatus = 2;
        }

        //  router.push({ path: `/blindbox/${item.id}` });
      }

      state.showPayTyoe = true;
    };
    const goToAuth = () => {
      nftUtils.setcnzzRecordByBtn('主页认证提示');
      router.push({ path: '/authentication' });
    };
    const showPublisher = () => {
      nftUtils.setcnzzRecordByBtn('主页显示看漫简介');
      state.show = true;
    };
    // 获取格式时间
    const getTime = (time, format = 'MM月DD日 HH:mm') => {
      return moment(time).format(format);
    };
    // 自动加QQ群
    const addQQ = () => {
      window.location.href =
        'https://qm.qq.com/cgi-bin/qm/qr?k=sBLr_awKhhIrMxbnLQB8noXbx3dyIhUD&jump_from=webapi';
    };
    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    // 倒计时结束触发
    const finistTime = async () => {
      await store.dispatch('getHomeList');
      state.list = store.state.homeList;
    };

    const toNationalSign = () => {
      router.push({ path: '/nationalSign' });
    };

    const tofootBallDraw = () => {
      router.push({ path: '/footballDraw/index' });
    };

    const toUpload = () => {
      nftUtils.setcnzzRecordByBtn('上传图片入口点击');
      router.push({ path: '/invite/updatedImage' });
    };

    return {
      ...toRefs(state),
      goToDetail,
      clickBtn,
      goToAuth,
      showPublisher,
      getTime,
      diffTime,
      finistTime,
      addQQ,
      showPrepaidPoup,
      getList,
      toinvit,
      onRefresh,
      bannerClick,
      clickStart,
      clickEnd,
      onChangeTab,
      getHistorylis,
      getBanner,
      drawLuckyBug,
      closeDrawPop,
      toNationalSign,
      tofootBallDraw,
      icon_arrow: require('../imgs/icon/icon_mine_jt.png'),
      pic_banner_text: require('../imgs/comm/pic_home_banner_txt.png'),
      pic_qq_qr: require('../imgs/comm/pic_qq_qr.png'),
      pic_wx_qr: require('../imgs/comm/pic_wx_qr.png'),
      getSource,
      toUpload
    };
  }
};
</script>
<style lang="scss">
.oneline {
  display: inline-block;
  white-space: nowrap;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabs-box {
  .van-tab--active {
    font-size: 18px;
  }
}
</style>
<style lang="scss" scoped>
@import '../styles/mixin.scss';

.signEntry {
  position: fixed;
  z-index: 1000;
  right: 0;
  top: 160px;

  img {
    width: 50px;
  }
}

.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  .content {
    padding: 16px;
    line-height: 26px;
    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .btn {
    div {
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      border-top: 1px solid #494359;
    }
  }
}
.dataNullBox {
  // position: absolute;
  // top: 40%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  text-align: center;
  margin-top: 52px;

  img {
    width: 150px;
    height: 110px;
  }

  .text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
  }
  .tohistory {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 26px;
  }
}
.history-box {
  margin-left: 16px;
  margin-top: 5px;
  min-height: 380px;
  .title {
    font-size: 14px;
    font-weight: 600;
    position: relative;
    margin-bottom: 16px;
    span {
      margin-left: 10px;
    }
    &::before {
      content: '';
      width: 4px;
      height: 15px;
      background: #58dedd;
      position: absolute;
      left: 0;
      top: 3px;
    }
  }
  .primary-color {
    color: $primary;
  }
  .sub-item-box {
    display: flex;
    overflow: auto;
    .card-item {
      border-radius: 4px;
      overflow: hidden;
      background: #000000;
      margin-bottom: 22px;
      box-sizing: border-box;
      margin-right: 13px;
      .img-box {
        position: relative;
      }
      .card-wrap {
        padding: 9px 27px 0;
        background-color: #1c172a;
        border: 1px solid #494359;
        border-bottom: none;
        box-sizing: border-box;
        border-radius: 4px 4px 0 0;
        font-size: 0px;
      }
      .card {
        width: 80px;
      }
      .over {
        background: #513d9e;
        border-radius: 4px 4px 4px 4px;

        font-size: 10px;
        color: #ffffff;
        position: absolute;
        top: 10px;

        padding: 2px 4px;
        left: 7px;
      }
      .msg-meng {
        width: 168px;
        height: 30px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, #000000 100%);
        opacity: 1;
        position: absolute;
        bottom: 0;
      }
      .msg {
        padding: 0 10px 7px;

        font-size: 12px;
        span {
          font-size: 10px;
        }
        .name {
          line-height: 26px;
          color: rgba(255, 255, 255, 0.7);
        }
      }
      .flex-bt {
        @include fj();
        align-items: flex-end;
        line-height: 16px;
        margin-top: 5px;
      }
    }
  }
}
.tabs-box {
  width: 70%;
  margin: 0 auto;
  --van-tab-font-size: 16px;
}
.my-swipe {
  padding: 16px 20px 0 20px;
}
.swipe {
  img {
    width: 100%;
    border-radius: 2px;
  }
}
.invit {
  font-size: 0px;
  position: fixed;
  bottom: 249px;
  right: 0;
  z-index: 999;
  img {
    width: 81px;
  }
}
.t-c {
  text-align: center;
}
.simple-header {
  position: absolute !important;
}
.home-bg {
  position: absolute;
  background-image: url('../imgs/comm/pic_home_bj.png');
  background-size: 100%;
  height: 276px;
  width: 100%;
}
.home-box {
  position: relative;
  width: 100%;

  .home-title {
    font-size: 18px;
    margin-top: 30px;
    text-align: center;
  }
  .home-auth-hint {
    background-color: $hc;

    width: 100%;
    height: 28px;
    display: flex;
    font-size: 12px;
    color: $fc;
    opacity: 0.8;
    bottom: 76px;
    align-items: center;
    align-content: center;
    position: fixed;
    left: 0;
    bottom: 76px;
    width: 100%;
    z-index: 1000;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    span {
      width: 100%;
      text-align: center;
    }
  }
  .home-hint {
    display: flex;
    margin-left: 27px;
    margin-right: 27px;
    margin-top: 27px;
    height: 57px;
    font-size: 12px;
    background-image: url('../imgs/comm/pic_home_hint_bg.png');
    background-size: 100%;
    .home-hint-text {
      margin-top: 15px;
      margin-left: 112px;
      opacity: 0.8;
    }
  }
  .home-qr {
    display: flex;
    margin-left: 27px;
    margin-right: 27px;
    margin-top: 3px;
    height: 99px;
    font-size: 12px;
    align-items: center;
    background-image: url('../imgs/comm/pic_home_banner_bg.png');
    background-size: 100%;
    &-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-txt {
        // position: absolute;
        font-size: 12px;
        transform: scale(0.75);
        white-space: nowrap;
        // margin-top: 90px;
        opacity: 0.8;
        word-break: break-all;
      }
      &-img {
        width: 73px;
        height: 73px;
        pointer-events: auto;
      }
    }
    &-box-1 {
      width: 73px;
      margin-left: 30px;
      // margin-bottom: 10px;
    }
    &-box-2 {
      width: 94px;
      margin-left: 11px;
      // margin-bottom: 10px;
    }
  }
  .goods {
    min-height: 380px;
    .good {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      margin-bottom: 24px;
      margin-left: 16px;
      margin-right: 16px;

      font-size: 16px;

      align-items: center;

      .primary-color {
        color: $primary;
        font-weight: bold;
      }
      .m-font {
        font-size: 24px;
      }
      .s-font {
        font-size: 12px;
      }
      .l-font {
        font-size: 30px;
      }
      .good-top {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        width: 100%;
        padding-top: 17px;
        padding-bottom: 18px;
        background-color: $primaryc;
        .good-header {
          display: flex;
          width: 315px;
          flex-direction: row;
          justify-content: space-between;
          color: $fc;
          font-size: 16px;
          opacity: 0.8;
          align-items: baseline;
        }

        .good-image {
          display: flex;
          margin-top: 15px;
          width: 315px;
          height: 315px;
          flex-direction: column;

          .van-image {
            width: 315px;
            height: 315px;
            float: left;
          }

          .tag-shadow {
            height: 62px;
            margin-top: -62px;
            z-index: 900;
            background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
          }
          .tag {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 82px;
            height: 26px;
            font-size: 12px;
            margin-top: -300px;
            margin-left: -5px;
            z-index: 900;
            background-color: #262551;
            .tag-no {
              color: #8a839e;
              font-size: 14px;
            }
          }
          .tag-bg {
            width: 72px;
            height: 26px;
            margin-top: -21px;
            z-index: 809;
            opacity: 0.5;
            background-color: #6e6d96;
          }
          .tag-detail {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 72px;
            height: 26px;
            font-size: 14px;
            color: $fc;
            z-index: 900;
            position: absolute;
            bottom: 29px;
            right: 20px;
            // margin-left: 245px;
            // margin-top: 235px;
            opacity: 0.8;
          }
        }
      }
      .good-bottom-line {
        height: 91px;

        width: 100%;
        background-image: url('../imgs/comm/pic_home_bottom_bg.png');
        background-size: 100%;
        align-items: baseline;
        justify-content: space-between;
        font-size: 16px;
      }
      .good-text-normal {
        margin-left: 14px;
        margin-top: 19px;
      }
      .item-title {
        margin-top: 8px;
      }
      .price-tips {
        font-size: 12px;
        padding: 3px 7px;
        margin-left: 3px;
        background: #2f293f;
        border-radius: 4px 4px 4px 4px;
      }

      .good-text-price {
        margin-right: 14px;
      }
      .line-price {
        font-size: 12px;
        text-decoration: line-through;
        color: rgba(255, 255, 255, 0.7);
      }
      .good-bottom {
        display: flex;
        height: 91px;

        width: 100%;
        background-image: url('../imgs/comm/pic_home_bottom_bg.png');
        background-size: 100%;
        align-items: baseline;
        justify-content: space-between;
        font-size: 16px;
      }

      .good-space {
        height: 30px;
      }
      .box-btn {
        margin-top: -25px;
      }
      .box-not-btn {
        margin-top: -15px;
      }
    }
  }

  .space-bottom {
    height: 120px;
  }
}
.van-action-sheet {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    background-color: $bc;
    .img-zs {
      height: 16px;
    }
    .icon_tc_gb {
      margin-left: 300px;
      width: 16px;
      height: 16px;
    }
    .img-title {
      height: 16px;
    }
    span {
      font-size: 20px;
      color: $fc;
      margin-top: -20px;
    }
  }
  .block {
    height: 80px;
    background-color: $bc;
  }
  .content {
    background-color: $bc;
    color: $fc;
    font-size: 14px;
    div {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}
</style>
