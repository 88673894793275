<template>
  <div id="invite-award">
    <van-popup class="vantPop" v-model:show="popShow">
      <div class="imgpop" @click="imgClick">
        <img :src="homePoup.img_url" alt="" />
        <div class="close" @click.stop="popShow = !popShow">
          <img src="../imgs/icon/close.png" alt="" />
          <!-- <van-icon name="close" /> -->
        </div>
      </div>
      <!-- <div class="popup">
        <div class="content">
          <div class="title">预付款用户专享福利</div>
          <div>
            {{
              prepaidItem.collections_name
            }}系列藏品正式开售前，所有完成预付款（即参与系统自动帮抢活动）的用户，均可在预付款后即刻获得
            <span class="yellow">神秘藏品空投</span>一份，为后续合成觉醒版的必备材料。
          </div>
        </div>
        <div class="btn">
          <div class="know" @click="zhidaole">知道了</div>
          <div class="checked">
            <van-checkbox v-model="checked">
              <span style="color: rgba(255, 255, 255, 0.7)">下次不再提示</span>
              <template #icon="props">
                <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
              </template>
            </van-checkbox>
          </div>
        </div>
      </div> -->
    </van-popup>
  </div>
</template>

<script>
import { reactive, toRefs, watch, onMounted } from 'vue';
import { nftUtils, domUtil, localStorageUtil } from '@/utils';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
  props: {
    prepaidItem: {
      type: Object
    },
    prepaidShow: {
      type: Boolean,
      default: false
    },
    homePoup: {
      type: Object
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      popShow: props.prepaidShow,
      checked: false
    });
    const imgClick = () => {
      // console.log(111);
      // router.push({ path: '/compound' });
      // window.location.href = window.location.origin + props.homePoup.link_url;
      window.location.href = props.homePoup.link_url;
    };
    const zhidaole = () => {
      state.checked && localStorageUtil.set('notShowPrepaid');
      store.commit('changeShowPrepaidHome', true);
      state.popShow = false;
    };

    return {
      ...toRefs(state),
      zhidaole,
      imgClick,
      inactiveIcon: require('../imgs/icon/Slice36.png'),
      activeIcon: require('../imgs/icon/Slice36right.png')
    };
  }
};
</script>

<style lang="scss" scoped>
.imgpop {
  position: relative;
  img {
    width: 340px;
  }
  .close {
    font-size: 28px;
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 28px;
      height: 28px;
    }
  }
}
.yellow {
  color: #ffe279;
}
.checked {
  color: rgba(255, 255, 255, 0.7);
  font-size: 10px;
  display: flex;
  justify-content: center;
  padding: 12px 0 14px 0;
}
.img-icon {
  height: 16px;
}
.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;

  .content {
    padding: 16px;
    line-height: 26px;
    padding-bottom: 24px;
    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .btn {
    // padding: 24px 0 12px 0;
    .know {
      // text-align: center;
      // line-height: 50px;
      margin: 0 auto;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 166px;
      height: 44px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid rgba(255, 255, 255, 0.7);
      //  border-top: 1px solid #494359;
    }
  }
}
</style>
<style lang="scss">
#invite-award {
  .vantPop {
    background: transparent !important;
    height: 400px;
  }
}
</style>
